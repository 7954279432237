import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import "./SavedSearch.css";
import { homeTypeDetails, getListingStatus,formatPrice } from '../../common/GenericFunction';
import { savedSearchdashboard } from '../../components/Api/ListingApi';
import { useSelector } from "react-redux";
import ToastPopup from '../../components/popup/ToastPopup';


export default function SavedSearchPopup(props) {
    // props declation //
    const { savedSearch, toggleSavedSearch, searchDataName, homeType, bed, bath, list_status, price, searchFields } = props;

    // redux data declaration //
    const userDetails = useSelector(state => state.user.userDetail);
    // usesate declaration //
    const [messages, setMessages] = useState("");
    const [success, setSuccess] = useState(false);
    const [notifyMeData, setNotifyMeData] = useState("")
    const [searchDataNameInput, setSearchDataNameInput] = useState(searchDataName); // Separate state for input value
    // toast popup //
    const toggleSuccess = () => setSuccess(prev => !prev);
    // name change onchange function //
    const handleSearchDataNameChange = (event) => {
        setSearchDataNameInput(event.target.value);
    };
    // notify me function //
    const handleSelectChange = (e) => {
        let selectedValue = e.target.value
        setNotifyMeData(selectedValue); // Pass the selected value to the function
    }
    // Saved search api integration //
    const toggleSaveSearches = async () => {
        let userId = localStorage.getItem("userId");
        let getParams = {
            name: searchDataNameInput ? searchDataNameInput : searchDataName, // Using the input value
            city: searchDataName, // Keeping the original city value
            Listing_type: "For Sale",
            listing_status: list_status,
            beds: bed,
            baths: bath,
            price: price?.min ? price.min : price.max,
            sub_class: homeType,
            priceRangeMin: price.min,
            priceRangeMax: price.max,
            notify_me: notifyMeData !== "" ? notifyMeData : "Instant",
            lot_size_min: searchFields?.lotSizeMin ? searchFields.lotSizeMin : null,
            lot_size_max: searchFields?.lotSizeMax ? searchFields.lotSizeMax : null,
            price_per_sqft_min: searchFields?.pricesquareFeetMin ? searchFields.pricesquareFeetMin : null,
            price_per_sqft_max: searchFields?.pricesquareFeetMax ? searchFields.pricesquareFeetMax : null,
            square_feet_min: searchFields?.squareFeetMin ? searchFields.squareFeetMin : null,
            square_feet_max: searchFields?.squareFeetMax ? searchFields.squareFeetMax : null,
            stories_min: searchFields?.storiesMax ? searchFields.storiesMax : null,
            stories_max: searchFields?.storiesMax ? searchFields.storiesMax : null,
            year_built_min: searchFields?.yearBuildMin ? searchFields.yearBuildMin : null,
            year_built_max: searchFields?.yearBuildMax ? searchFields.yearBuildMax : null
        };

        if (userDetails.role === "agent") {
            getParams.agent_id = userId;
        } else {
            getParams.user_id = userId;
        }

        try {
            const res = await savedSearchdashboard(getParams);
            setMessages(res.data.message);
            toggleSuccess();
            setTimeout(() => toggleSuccess(), 3000);
        } catch (error) {
            // Handle error
            console.error("Error saving search:", error);
        }
    };

 // Number format function //
 const nFormatter = (num, digits) => {
    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" }, // Modify "M" to " million"
        { value: 1e9, symbol: "B" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: " quadrillion" },
        { value: 1e18, symbol: " quintillion" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function (item) {
        return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : '';
};
    return (
        <div>
            <ToastPopup success={success} toggleSuccess={toggleSuccess} messages={messages} />
            <Modal show={savedSearch} onHide={toggleSavedSearch} className="modelparent">
                <div className="PopUpBorderDiv">
                    <Modal.Header closeButton className="team-popup-margin1 teampopup-title savedSearchshare ">
                        <Modal.Title className='form-field-label form-label-team ' ><h4 className='savedSearchtitle'>Save your search</h4></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="team-popup-margin1 pb-0">
                        <div>
                            <div className='position-relative savedSearchinput mb-3' >
                                <h5 className='savedSearchtitle1'>Name your search</h5>
                                <input
                                    type="search"
                                    className="form-control"
                                    value={searchDataNameInput ? searchDataNameInput : searchDataName} // Using the input value
                                    onChange={handleSearchDataNameChange}
                                    placeholder="Search name"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    style={{ borderRadius: "0px" }}
                                />
                                <div className='savedsearchClose position-absolute cursor-pointer'>
                                    <img src="/images/closeIconProfile.png" alt="close" style={{ height: "18px" }} />
                                </div>
                                <div style={{ height: "140px" }}>
                                    <p className='savedSearchdata mt-3'>
                                        For Sale: {getListingStatus(list_status) && `${getListingStatus(list_status)}, `}
                                        {price.displayMin || price.displayMax ? `${price.displayMin ? `${price.displayMin} - ` : ''}${price.displayMax}` : ''}
                                        {bed && `, ${bed} Beds`}
                                        {bath && `${bed && bath ? ', ' : ''}${bath} Baths, `}
                                        {homeType &&
                                            homeType.filter(Boolean).length > 0 &&
                                            homeType.filter(Boolean).map((item, index, array) =>
                                                index === array.length - 1 ? homeTypeDetails(item) : `${homeTypeDetails(item)}, `
                                            )}
                                        {searchFields.lotSizeMin && searchFields.lotSizeMax && `, Lot size: ${formatPrice(searchFields.lotSizeMin)} - ${formatPrice(searchFields.lotSizeMax)}`}
                                        {searchFields.squareFeetMin && searchFields.squareFeetMax && `, Sq.Ft: ${formatPrice(searchFields.squareFeetMin)} - ${formatPrice(searchFields.squareFeetMax)}`}
                                        {searchFields.storiesMin && searchFields.storiesMax && `, Stories: ${searchFields.storiesMin} - ${searchFields.storiesMax}`}
                                        {searchFields.yearBuildMin && searchFields.yearBuildMax && `, Year built: ${searchFields.yearBuildMin} - ${searchFields.yearBuildMax}`}
                                        {searchFields.pricesquareFeetMin && searchFields.pricesquareFeetMax && `, Price per square feet: ${formatPrice(searchFields.pricesquareFeetMin)} - ${formatPrice(searchFields.pricesquareFeetMax)}`}
                                    </p>
                                </div>
                                <div>
                                    <h5 className='savedSearchtitle1'>Notify me</h5>
                                    <select class="form-select" aria-label="Default select example" onChange={handleSelectChange}>
                                        <option value="Instant">Instant</option>
                                        <option value="Daily">Daily</option>
                                        <option value="Never">Never</option>
                                    </select>
                                </div>
                            </div>

                            <div className='mb-3'>
                                <Button className="mb-3 mt-3 savedSearchbut" onClick={() => { toggleSaveSearches(); toggleSavedSearch() }}>Save Search</Button>
                            </div>
                        </div>
                    </Modal.Body>
                </div>
            </Modal>
        </div>
    )
}